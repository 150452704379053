import Vue from 'vue';
import Router from 'vue-router';

// utils
import isAuthenticated from './utils/auth';

// routes
import authentication from '../modules/authentication/routes';
import dashboard from '../modules/dashboard/routes';
import droneView from '../modules/droneView/routes';

Vue.use(Router);

function redirectHome(to, from, next) {
  if (isAuthenticated()) {
    next('/dashboard');
  } else {
    next();
  }
}

const defaultRoutes = [
  { path: '/api' },
  {
    name: 'default',
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../modules/authentication/pages/login/View.vue'),
    beforeEnter: redirectHome,
  },
  {
    path: '*', // 404 wildcard
    name: '*',
    component: () => import('../modules/404/View.vue'),
  },
];

const routes = [
  ...defaultRoutes,
  ...authentication,
  ...dashboard,
  ...droneView,
];

const router = new Router({
  base: process.env.VUE_APP_BASE_PATH || '/',
  mode: 'history',
  routes,
});

// router guards
router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next();
  } else if (isAuthenticated()) {
    next();
  } else {
    next({ path: 'login' });
  }
});

export default router;
