import { get, getBlob } from './http';

export async function getMission(missionId) {
  const { data } = await get(`api/mission/?mission=${missionId}`);
  return data;
}
export async function listMissions(orderId) {
  let request;
  if (orderId) {
    request = await get(`api/mission/list-by-order/?orderId=${orderId}`);
  } else {
    request = await get('api/mission/list');
  }
  const { data } = request;
  return data;
}

export function downloadMission(missionId, parseType) {
  return getBlob(`api/mission/download/?mission=${missionId}&parseType=${parseType}`)
    .then((res) => {
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const fileSrc = window.URL.createObjectURL(blob);
      return fileSrc;
    })
    .catch((e) => console.error(e));
}
