import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';
import store from './store';
import AzureAdAuthenticator from './utils/azureAdAuthenticator';
import CamAuth from './utils/camAuth';

// CSS
import './style/main.scss';
import './style/colors.scss';

// Icons
import '@fortawesome/fontawesome-free/css/all.min.css';

Vue.use(Buefy, {
  defaultIconPack: 'fas',
});
Vue.config.productionTip = true;

Vue.prototype.$azureAdAuthenticator = new AzureAdAuthenticator();
Vue.prototype.$camAuth = new CamAuth();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
