<template>
  <div id="app-container">
    <navbar />
    <div
      v-if="renderRouter"
      class="router-view"
    >
      <router-view></router-view>
    </div>
    <div
      v-else
      style="padding-left: 2rem;"
    >
      <i class="fas fa-spinner fa-spin" />
      Authenticating
    </div>
  </div>
</template>

<script>
import Navbar from './modules/navbar/components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      renderRouter: false,
    };
  },
  computed: {
    clientId() {
      const whitelistedSubDomains = process.env.VUE_APP_WHITELISTED_SUBDOMAINS
        .toLowerCase().split(',');
      const hostNameSections = window.location.hostname.split('.');
      if (hostNameSections[hostNameSections.length - 1] === 'localhost') {
        hostNameSections.push('Adding one section to allow local development');
      }
      const { length } = hostNameSections;

      /*
      const hostNameSections = window.location.hostname.split('.');
      console.log(hostNameSections);
      const { length } = hostNameSections;
      if (length === 1) {
        return null;
      }
      */
      if (length === 2 || whitelistedSubDomains.includes(hostNameSections[0])
      ) {
        return null;
      }
      return hostNameSections[0];
    },
  },
  mounted() {
    this.updateWindowDimensions();
    this.$store.dispatch('users/loadUsers');
    window.addEventListener('resize', this.updateWindowDimensions);
  },
  created() {
    console.log('clientId', this.clientId);
    if (this.clientId) {
      this.initCamAuth();
    } else {
      this.renderRouter = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  },
  methods: {
    updateWindowDimensions() {
      this.$store.dispatch('window/updateDimensions');
    },
    async initCamAuth() {
      await this.$camAuth.initialize(this.clientId);
      await this.$camAuth.signIn();
      this.renderRouter = true;
    },
  },
};
</script>

<style lang="scss">
@import './src/style/colors.scss';

#app-container {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  background: $appBgColor;
}
.router-view {
  flex: 1 1 0;
  min-height: 0;
  overflow: hidden;
  display: flex;
}
</style>
