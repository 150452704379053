import axios from 'axios';

export const BASE_URL = process.env.VUE_APP_BACKEND_URI;

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

function getHeaders(headerOptions) {
  let defaultHeaders = DEFAULT_HEADERS;

  let _token = localStorage.getItem('user-token');

  if (headerOptions) {
    if (headerOptions.multipart) {
      defaultHeaders = {};
    }

    if (headerOptions.token) {
      _token = headerOptions.token;
    }
  }

  if (_token) {
    defaultHeaders = {
      Authorization: `Bearer ${_token}`,
      ...defaultHeaders,
    };
  }

  return defaultHeaders;
}

export function get(uri, headerOptions, baseURL) {
  return axios.get(uri, {
    baseURL: baseURL || BASE_URL,
    headers: getHeaders(headerOptions),
    withCredentials: true,
  });
}

export function getBlob(uri, headerOptions, requestOptions) {
  return axios.get(uri, {
    baseURL: BASE_URL,
    responseType: 'blob',
    headers: getHeaders(headerOptions),
    withCredentials: true,
    ...requestOptions,
  });
}

export function post(uri, data, headerOptions, requestOptions) {
  return axios.post(uri, data, {
    baseURL: BASE_URL,
    headers: getHeaders(headerOptions),
    withCredentials: true,
    ...requestOptions,
  });
}

export function put(uri, data, headerOptions) {
  return axios.put(uri, data, {
    headers: getHeaders(headerOptions),
    withCredentials: true,
    baseURL: BASE_URL,
  });
}

export function patch(uri, data, headerOptions) {
  return axios.patch(uri, data, {
    headers: getHeaders(headerOptions),
    withCredentials: true,
    baseURL: BASE_URL,
  });
}

export function remove(uri, payload, headerOptions) {
  return axios.delete(uri, {
    baseURL: BASE_URL,
    headers: getHeaders(headerOptions),
    withCredentials: true,
    data: payload,
  });
}

export function upload(uri, data, headerOptions) {
  return fetch(uri, {
    baseURL: BASE_URL,
    data,
    headers: getHeaders({ ...headerOptions, multipart: true }),
    cors: true,
    method: 'POST',
  });
}
