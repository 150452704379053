import * as msal from '@azure/msal-browser';

export default class AzureAdAuthenticatorV2 {
  constructor() {
    this.accountId = undefined;
    this.loginRequest = {
      scopes: ['User.Read'],
    };

    this.silentRequest = {
      scopes: ['openid', 'profile', 'User.Read'],
    };

    // Config object to be passed to Msal on creation
    this.msalConfig = {
      auth: {
        clientId: process.env.VUE_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/common',
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          logLevel: msal.LogLevel.Trace,
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                break;
              case msal.LogLevel.Info:
                console.info(message);
                break;
              case msal.LogLevel.Verbose:
                console.debug(message);
                break;
              case msal.LogLevel.Warning:
                console.warn(message);
                break;
              default:
                console.log(message);
                break;
            }
          },
        },
      },
    };

    this.msalInstance = new msal.PublicClientApplication(this.msalConfig);
  }

  async isAuthenticated() {
    try {
      await this.msalInstance.ssoSilent(this.silentRequest);
      return true;
    } catch (e) {
      return false;
    }
  }

  signInWithAD() {
    return new Promise((resolve, reject) => {
      this.msalInstance.loginPopup({
        ...this.loginRequest,
        redirectUri: '/',
      })
        .then(async (response) => {
          if (response !== null) {
            this.accountId = response.account.homeAccountId;
            this.msalInstance.setActiveAccount(response.account);
            const { idToken, accessToken } = response;
            return resolve({ idToken, accessToken });
          }

          console.error('empty response from the login popup');
          return reject(new Error('empty response from the login popup'));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
}
