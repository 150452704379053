export const SI = 'si';
export const IMPERIAL = 'imperial';

// accepted unit distance systems
const unitSystems = [SI, IMPERIAL];

export function isValidSystem(system) {
  if (unitSystems.indexOf(system) >= 0) return true;
  console.error('Invalid unit system', system);
  return false;
}

// si transforms
export function metersToFeet(meters) {
  return meters * 3.280840;
}

export function celsiusToFahrenheit(celsius) {
  return (celsius * 1.8) + 32;
}

export function metersPerSecondToKnots(metersPerSecond) {
  return metersPerSecond * 1.94384449;
}

export function metersPerSecondToFeetPerMinute(metersPerSecond) {
  return metersPerSecond * 196.850394;
}

// persistent preferences
export function setSystem(system) {
  if (!isValidSystem(system)) return;
  localStorage.setItem('unitSystem', system);
}

export function getSystem() {
  let system = localStorage.getItem('unitSystem');

  // clear system if invalid to fallback to si
  if (system && !isValidSystem(system)) {
    system = null;
  }

  if (!system) {
    setSystem(SI);
    system = SI;
  }

  return system;
}

export function getDistanceInSystemUnit(meters) {
  const system = getSystem();

  if (system === SI) {
    return {
      value: Number(meters),
      unit: 'm',
    };
  }

  if (system === IMPERIAL) {
    return {
      value: metersToFeet(Number(meters)),
      unit: 'ft',
    };
  }

  return {
    value: NaN,
    unit: 'n/a',
  };
}

export function getTemperatureInSystemUnit(temperatureInCelsius) {
  const system = getSystem();

  if (system === SI) {
    return {
      value: temperatureInCelsius,
      unit: 'C',
    };
  }

  if (system === IMPERIAL) {
    return {
      value: celsiusToFahrenheit(temperatureInCelsius),
      unit: 'F',
    };
  }

  return {
    value: NaN,
    unit: 'n/a',
  };
}

export function getVerticalSpeedInSystemUnit(metersPerSecond) {
  const system = getSystem();

  if (system === SI) {
    return {
      value: Number(metersPerSecond),
      unit: 'm/s',
    };
  }

  if (system === IMPERIAL) {
    return {
      value: metersPerSecondToFeetPerMinute(Number(metersPerSecond)),
      unit: 'fpm',
    };
  }

  return {
    value: NaN,
    unit: 'n/a',
  };
}

export function getGroundSpeedInSystemUnit(metersPerSecond) {
  const system = getSystem();

  if (system === SI) {
    return {
      value: Number(metersPerSecond),
      unit: 'm/s',
    };
  }

  if (system === IMPERIAL) {
    return {
      value: metersPerSecondToKnots(Number(metersPerSecond)),
      unit: 'knots',
    };
  }

  return {
    value: NaN,
    unit: 'n/a',
  };
}

export function getAltitudeInSystemUnit(meters) {
  return getDistanceInSystemUnit(meters);
}
