import * as MissionApi from '@/api/mission';

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    isLoading: false,
    missions: new Map(),
    orderMissions: new Map(),
  },
  mutations: {
    SET_IS_LOADING: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    SET_MISSIONS: (state, missions) => {
      missions.forEach((mission) => state.missions.set(mission._id, mission));
    },
    SET_ORDER_MISSIONS: (state, { orderId, missions }) => {
      const missionIds = new Set();
      missions.forEach((mission) => missionIds.add(mission._id));
      state.orderMissions.set(orderId, missionIds);
    },
  },
  actions: {
    loadMissions: async ({ commit }, { orderId }) => {
      commit('SET_IS_LOADING', true);
      if (orderId) {
        const missions = await MissionApi.listMissions(orderId);
        commit('SET_MISSIONS', missions);
        commit('SET_ORDER_MISSIONS', { orderId, missions });
      }
      commit('SET_IS_LOADING', false);
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    missions: (state) => ({ orderId, missionIds }) => {
      const fromIds = (ids) => new Map([...ids].map((id) => [id, state.missions.get(id)]));
      if (orderId) {
        return fromIds(state.orderMissions.get(orderId));
      }

      if (missionIds) {
        return fromIds(missionIds);
      }

      return state.missions;
    },
  },
};
