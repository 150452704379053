<template>
  <b-dropdown
    position="is-bottom-left"
    class="ui-settings"
  >
    <template #trigger>
      <b-button
        class="kvs-button"
        icon-left="desktop"
        icon-right="caret-down"
      >
        UI Settings
      </b-button>
    </template>

    <b-dropdown-item custom>
      <unit-switch />
    </b-dropdown-item>

    <div class="ui-settings-disclaimer">
      Changes made here will only apply to this browser on this device.
      Settings on drones, payloads, or other devices will <u>not</u> be affected.
    </div>
  </b-dropdown>
</template>

<script>
import UnitSwitch from '@/modules/droneView/toolPanel/UnitSwitch.vue';

export default {
  components: {
    UnitSwitch,
  },
};
</script>

<style lang="scss" scoped>
.ui-settings-disclaimer {
  border-top: 1px solid rgba(black,.1);
  font-size: .75rem;
  color: rgba(black, .6);
  padding: .5rem;
  margin-top: .5rem;
}
</style>

<style lang="scss">
.ui-settings {

  .dropdown-content {
    min-width: 20rem;
  }
}
</style>
