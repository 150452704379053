import * as UserApi from '../api/users';
import CamApi from '../api/cam';

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    isLoading: false,
    users: new Map(),
  },
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_USERS(state, users) {
      users.forEach((user) => {
        state.users.set(user._id, user);
      });
    },
    SET_USER(state, { userId, user }) {
      state.users.set(userId, user);
    },
  },
  actions: {
    loadUsers: async ({ commit, dispatch }) => {
      commit('SET_IS_LOADING', true);
      const users = await UserApi.list();

      const legacyUsers = [];
      const camUserPromises = [];

      users.forEach((user) => {
        if (user.camUserId) {
          const { camUserId } = user;
          camUserPromises.push(dispatch('loadCamUser', camUserId));
        } else {
          legacyUsers.push(user);
        }
      });
      commit('SET_USERS', legacyUsers);
      await Promise.all(camUserPromises);

      commit('SET_IS_LOADING', false);
    },
    loadCamUser: async ({ commit }, camUserId) => {
      try {
        const user = await CamApi.getUser(camUserId);
        commit('SET_USER', { userId: camUserId, user });
      } catch (e) {
        console.error('Failed to load cam user', e);
      }
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    users: (state) => state.users,
    user: (state) => (id) => {
      const user = state.users.get(id);
      if (!user) {
        return {
          _id: 'unknown-user',
          firstName: 'Unknown',
          lastName: 'User',
        };
      }
      return user;
    },
    userInfo: (state, getters) => (id) => {
      const user = getters.user(id);

      if (user.firstName && user.lastName) {
        user.name = `${user.firstName} ${user.lastName}`;
      }

      user.shortname = user.name.split(' ').map((part, index, names) => {
        if (index === names.length - 1) {
          return part;
        }
        return `${part.charAt(0)}`;
      }).join('. ');

      return user;
    },
  },
};
