import { getCockpit } from '@/api/pilotView';
import { getById } from '@/api/drones';

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    isLoading: false,
    drone: null,
    uavModelCockpit: null,
  },
  mutations: {
    SET_DRONE(state, drone) {
      state.drone = drone;
    },
    SET_UAV_MODEL_COCKPIT(state, uavModelCockpit) {
      state.uavModelCockpit = uavModelCockpit;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    selectDrone: async ({ commit }, droneId) => {
      commit('SET_IS_LOADING', true);
      try {
        const drone = (await getById(droneId));
        commit('SET_DRONE', drone);

        const { uavModel: { id: uavModelId } } = drone;
        const cockpitVersionId = process.env.VUE_APP_COCKPIT_VERSION_ID;

        const uavModelCockpit = (await getCockpit({
          cockpitVersionId,
          uavModelId,
        })).data;
        commit('SET_UAV_MODEL_COCKPIT', uavModelCockpit);
      } catch (e) {
        console.error(e);
      } finally {
        commit('SET_IS_LOADING', false);
      }
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    droneId: (state) => state.droneId,
    drone: (state) => state.drone,
    uavModelCockpit: (state) => state.uavModelCockpit,
    indicators: (state, getters) => getters.uavModelCockpit?.indicators || [],
    indicatorComponentIds: (state, getters) => getters.indicators.map((i) => i.componentId),
    batteries: (state, getters) => getters.uavModelCockpit?.uavModel?.airframe?.batteries || 0,
    airframeType: (state, getters) => getters.uavModelCockpit?.uavModel?.airframe?.frameType || '',
    indicatorEnabled: (state, getters) => (componentId) => getters.indicatorComponentIds
      .includes(componentId),
  },
};
