import * as UserApi from '../../api/users';

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    userId: localStorage.getItem('user-id') || '',
    token: localStorage.getItem('user-token') || '',
    authenticated: localStorage.getItem('user-token') !== null,
    loginFailed: false,
    logoutMethod: () => null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      localStorage.setItem('user-token', token);
      state.token = token;
    },
    SET_USER_ID(state, userId) {
      localStorage.setItem('user-id', userId);
      state.userId = userId;
    },
    SET_LOGIN_FAILED(state, status) {
      state.loginFailed = status;
    },
    SET_LOGOUT_METHOD(state, logoutMethod) {
      state.logoutMethod = logoutMethod;
    },
    LOG_OUT(state) {
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-id');
      state.authenticated = false;
    },
  },
  actions: {
    login: async ({ commit }, credentials) => {
      commit('SET_LOGIN_FAILED', false);
      UserApi.login(credentials.email, credentials.password)
        .then((res) => {
          commit('SET_TOKEN', res.data.token);
          commit('SET_USER_ID', res.data.userId);
          commit('SET_LOGIN_FAILED', false);
          window.history.go();
        })
        .catch(() => {
          commit('SET_LOGIN_FAILED', 'Please check your username and password and try again.');
        });
    },
    logout: ({ commit, state }) => {
      commit('LOG_OUT');
      state.logoutMethod();
      window.history.go();
    },
    loginWithAzureAd: ({ commit }, { accessToken }) => {
      commit('SET_LOGIN_FAILED', false);
      UserApi.loginWithAzure(accessToken)
        .then((res) => {
          commit('SET_TOKEN', res.data.token);
          commit('SET_USER_ID', res.data.userId);
          commit('SET_LOGIN_FAILED', false);
          window.history.go();
        })
        .catch(() => {
          commit('SET_LOGIN_FAILED', 'Microsoft login failed');
        });
    },
    loginWithCam: async ({ commit }, { accessToken, logoutMethod }) => {
      commit('SET_TOKEN', accessToken);

      const { data: userInfo } = await UserApi.getUserInfo();

      commit('SET_USER_ID', userInfo._id);
      commit('SET_LOGOUT_METHOD', logoutMethod);
    },
    updateToken: ({ commit }, appToken) => {
      commit('SET_TOKEN', appToken);
    },
  },
  getters: {
    token: (state) => state.token,
    userId: (state) => state.userId,
    authenticated: (state) => state.authenticated,
    loginFailed: (state) => state.loginFailed,
  },
};
