import { post, get, remove } from './http';

export function login(email, password) {
  const payload = { email, password };
  return post('/api/users/signin', payload);
}

export function loginWithAzure(accessToken) {
  return post('/api/azure/signin', { accessToken });
}

export async function getById(id) {
  const { data: user } = await get(`/api/users/?userId=${id}`);
  return user;
}

export function getUserInfo() {
  return get('/api/users/user-info');
}

export function getOrganizationToken(orgId) {
  return get(`/api/users/get-organization-token?organizationId=${orgId}`);
}

export function listFeatures() {
  return get('/api/users/list-feature');
}

export async function list() {
  return (await get('/api/users/list')).data;
}

export function createUser(
  firstName,
  lastName,
  email,
  password,
  organization,
  roles,
) {
  const payload = {
    firstName,
    lastName,
    email,
    password,
    organization,
    roles,
  };
  return post('/api/users/create', payload);
}

export function updateUser(_id, options) {
  const payload = {
    _id,
  };
  if (options.firstName) payload.firstName = options.firstName;
  if (options.lastName) payload.lastName = options.lastName;
  if (options.email) payload.email = options.email;
  if (options.azureId) payload.azureId = options.azureId;
  return post('/api/users/update', payload);
}

export function setAdmin(id, enable) {
  console.log(enable);
  return post(`/api/users/${id}/set-admin`, { enable });
}

export function setSysadmin(id, enable) {
  return post(`/api/users/${id}/set-sysadmin`, { enable });
}

export function setPassword(id, oldPassword, newPassword) {
  return post(`/api/users/${id}/set-password`, { oldPassword, newPassword });
}

export function deleteUser(id) {
  return remove(`/api/users/delete/${id}`);
}
