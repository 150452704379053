const narrowBreakpoint = 1500;

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    width: -1,
    height: -1,
  },
  mutations: {
    UPDATE_DIMENSIONS(state) {
      const { innerWidth, innerHeight } = window;
      state.width = innerWidth;
      state.height = innerHeight;
    },
  },
  actions: {
    updateDimensions: ({ commit }) => {
      commit('UPDATE_DIMENSIONS');
    },
  },
  getters: {
    width: (state) => state.width,
    height: (state) => state.height,
    isNarrow: (state) => state.width < narrowBreakpoint,
  },
};
