import { get, patch } from './http';

export async function list() {
  const { data: drones } = await get('api/robots/list');
  return drones;
}

export async function getById(id) {
  const { data: drone } = await get(`api/robots/?robotId=${id}`);
  return drone;
}

export function setMission(droneId, missionId) {
  return patch('api/robots/set-mission', {
    robotId: droneId,
    missionId,
  });
}
