<template>
  <b-field class="unit-switch">
    <template #label>
      <b-tooltip
        type="is-dark"
        position="is-bottom"
        dashed
      >
        <template #content>
          <h6>Imperial</h6>
          <table class="table">
            <tr>
              <td><strong>Ground speed</strong></td><td>knots</td>
            </tr>
            <tr>
              <td><strong>Vertical speed</strong></td><td>fpm</td>
            </tr>
            <tr>
              <td><strong>Altitude</strong></td><td>ft</td>
            </tr>
            <tr>
              <td><strong>Temperature</strong></td><td>°F</td>
            </tr>
          </table>

          <h6>SI</h6>
          <table class="table">
            <tr>
              <td><strong>Ground speed</strong></td><td>m/s</td>
            </tr>
            <tr>
              <td><strong>Vertical speed</strong></td><td>m/s</td>
            </tr>
            <tr>
              <td><strong>Altitude</strong></td><td>m</td>
            </tr>
            <tr>
              <td><strong>Temperature</strong></td><td>°C</td>
            </tr>
          </table>
        </template>
        Display units
        <i class="fas fa-question-circle" />
      </b-tooltip>
    </template>

    <b-radio-button
      v-for="unit in units"
      :key="unit.value"
      v-model="unitSystem"
      :native-value="unit.value"
      type="is-link"
      @input="setUnitSystem(unitSystem)"
    >
      <div
        style="margin-right: .5rem"
      >
        {{ unit.label }}
      </div>
      <i
        v-if="unit.value === unitSystem"
        class="fas fa-check-circle"
      />
      <div
        v-else
        style="
          height: .9rem;
          width: .9rem;
          border: 1px solid #333;
          border-radius: 1rem;
        "
      />
    </b-radio-button>
  </b-field>
</template>

<script>
import {
  IMPERIAL, getSystem, setSystem,
} from '../../../utils/systemUnits';

export default {
  data() {
    return {
      unitSystem: '',
      units: [
        {
          label: 'Imperial',
          value: 'imperial',
        },
        {
          label: 'SI',
          value: 'si',
        },
      ],
    };
  },
  computed: {
    isImperial() {
      return this.unitSystem === IMPERIAL;
    },
  },
  beforeMount() {
    this.unitSystem = getSystem();
  },
  methods: {
    setUnitSystem(system) {
      setSystem(system);
    },
  },
};
</script>

<style lang="scss" scoped>
.dv-us-container {
  display: flex;
  justify-content: flex-end;
  overflow: auto;
  padding: 0.5rem;
  gap: 0.55rem;
  align-items: center;
  flex-wrap: wrap;
}
</style>

<style lang="scss">
.unit-switch {
  .b-tooltip.is-bottom .tooltip-content {
    text-align: left;
    .table {
      width: 100%;
    }
  }
}
</style>
