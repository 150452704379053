import axios from 'axios';

const getMe = async () => {
  const { data } = await axios.get(
    '/api/cam/v1/users/me',
    { headers: { Authorization: `bearer ${localStorage.getItem('user-token')}` } },
  );

  return {
    id: data.self.id,
    name: data.name,
    email: data.email,
    clientId: data.client.self.id,
    clientName: data.client.name,
  };
};

const getAvailableClients = async () => {
  const { data } = await axios.get(
    '/api/cam/v1/users/me/available-clients',
    { headers: { Authorization: `bearer ${localStorage.getItem('user-token')}` } },
  );

  return data.value;
};

const getUser = async (userId) => {
  const { data } = await axios.get(
    `/api/cam/v1/users/${userId}`,
    { headers: { Authorization: `bearer ${localStorage.getItem('user-token')}` } },
  );

  return data;
};

export default {
  getMe,
  getAvailableClients,
  getUser,
};
