import store from '../../store';

function kvsAuthenticated() {
  return store.getters['authentication/authenticated'];
}

export default function isAuthenticated() {
  if (kvsAuthenticated()) return true;
  return false;
}
