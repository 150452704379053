import * as msal from '@azure/msal-browser';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import store from '../store';

const appClientId = process.env.VUE_APP_CLIENT_ID;

const redirectToLoginPage = () => window.location.assign(`/login?url=${window.location.href}`);

export default class CamAuth {
  async initialize(clientId) {
    this.clientId = clientId;

    const authority = localStorage.getItem('msal-authority');
    const knownAuthorities = localStorage.getItem('msal-knownAuthority')?.split(',');

    this.msalConfig = {
      auth: {
        clientId: appClientId,
        authority,
        knownAuthorities,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    };

    this.msalInstance = new msal.PublicClientApplication(this.msalConfig);
    await this.msalInstance.initialize();
    await this.msalInstance.handleRedirectPromise();
  }

  async signIn() {
    await this.updateAccessToken();

    await store.dispatch('authentication/loginWithCam', { accessToken: this.accessToken, logoutMethod: () => this.logOut() });

    return this.accessToken;
  }

  async updateAccessToken() {
    try {
      const { idToken } = await this.msalInstance.acquireTokenSilent({ scopes: [] });

      const res = await axios.post(
        '/api/cam/v1/access-tokens/generate',
        { clientSlug: this.clientId },
        { headers: { Authorization: `bearer ${idToken}` } },
      );
      if (res.status === 200) {
        this.accessToken = res.data.value.token;
      } else {
        throw new Error('could not get access token: ', res.data);
      }
      await store.dispatch('authentication/updateToken', this.accessToken);

      const decodedToken = jwtDecode(this.accessToken);
      const expireTime = decodedToken.exp * 1000;
      const difference = (expireTime - (1 * 60 * 1000)) - new Date().getTime();

      setTimeout(() => this.updateAccessToken(), difference);
    } catch (error) {
      console.error('Error updating access token', error);
      redirectToLoginPage();
    }
  }

  logOut() {
    this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.href,
    });
  }
}
