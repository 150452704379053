<template>
  <div
    v-if="isAuthenticated"
    class="pv-navbar"
  >
    <div
      class="navbar-logo"
      :class="{
        'navbar-logo-is-disabled': !showBackToDashboardNavigation,
      }"
      @click="goToDashboard()"
    >
      <i class="back-navigation-icon fas fa-chevron-left"></i>
      <img
        src="../../../assets/logo.png"
        class="navbar-logo-icon"
        alt="Field"
      >
      <h5>
        Pilot view
      </h5>
      <p v-if="versionNumber">
        {{ versionNumber }}
      </p>
    </div>
    <div class="navbar-buttons">
      <ui-settings v-if="showUiSettings" />
      <b-button
        v-if="showSignOutButton"
        class="kvs-button"
        icon-left="sign-out-alt"
        @click="logout()"
      >
        Sign out
      </b-button>
    </div>
  </div>
</template>

<script>
import UiSettings from '@/components/UiSettings.vue';

export default {
  components: {
    UiSettings,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['authentication/authenticated'];
    },
    showBackToDashboardNavigation() {
      return this.$route.path !== '/dashboard';
    },
    showSignOutButton() {
      return this.$route.path === '/dashboard';
    },
    showUiSettings() {
      return this.$route.path === '/dashboard';
    },
    versionNumber() {
      return process.env.VUE_APP_VERSION_NUMBER;
    },
  },
  methods: {
    goToDashboard() {
      this.$router.push('/dashboard');
    },
    logout() {
      this.$store.dispatch('authentication/logout');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/main.scss';

.pv-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $appBgColor;
  height: $navbar-height;
}

.navbar-logo {
  display: flex;
  gap: .67rem;
  align-items: center;
  height: 100%;
  color: #414d54;
  padding-right: 1rem;
  user-select: none;
  margin-left: -4.5rem;
  padding-left: 3rem;
  transition: all 0.065s ease-out;
  .back-navigation-icon {
    padding-right: 1rem;
  }
  .navbar-logo-icon {
    background-size: cover;
    height: 1rem;
  }
  p {
    font-weight: 400;
    background: rgba(0,0,0,0.1);
    padding: .125rem .25rem;
    border-radius: .25rem;
    font-size: .8rem;
    margin-top: 2px;
    line-height: 1rem;
    color: rgba(0,0,0,0.8);
  }
  &.navbar-logo-is-disabled {
    pointer-events: none;
  }
  &:not(.navbar-logo-is-disabled) {
    &:hover {
      margin-left: 0;
      padding-left: 1rem;
      cursor: pointer;
      background: linear-gradient(
        90deg,
        rgba(0,0,0,.1),
        rgba(0,0,0,0),
      );
    }
  }
}

.navbar-buttons {
  display: flex;
  gap: .5rem;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}
</style>
